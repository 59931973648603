<template>
  <div>
    <b-row class="content-header">
      <b-col class="content-header-left mb-2" cols="12" md="10">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Device Details
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in breadcrumbs"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-card title="Device Details">
      <b-row>
        <b-col cols="2">
          <div class="device-image text-left mx-auto">
            <img
              :src="model.media.image"
              class="w-100"
              :alt="model.media.alt"
            />
          </div>
          <MediaUploader
            :isMultiple="false"
            @mediaSelected="handleModelImageChange"
            text="Change Image"
            size="sm"
          />
        </b-col>
        <b-col cols="6" class="align-self-center">
          <h4>Model: {{ model.name }}</h4>
          <span class="d-block">
            Created: {{ getFormattedTime(model.createdAt) }}
          </span>
          <span class="d-block">
            Last Updated: {{ getFormattedTime(model.updatedAt) }}
          </span>
        </b-col>
        <b-col cols="4">
          <div class="text-right">
            <b-button
              variant="primary"
              @click="updateDeviceDetails"
              class="d-block mx-auto mt-1"
            >
              <b-spinner v-if="updatingModel" small />Update Device
              Details</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-title>
            <div class="issue-card-title">
              <div>
                <h4 class="card-title">Listed Issues</h4>
              </div>
              <div>
                <b-button
                  @click="addMoreIssues"
                  variant="outline-dark"
                  size="sm"
                  >Add Issue <feather-icon icon="PlusIcon"></feather-icon>
                </b-button>
              </div>
            </div>
          </b-card-title>
          <b-row>
            <b-col cols="2">
              <h6>Issue</h6>
            </b-col>
            <b-col cols="2">
              <h6>Price Details</h6>
            </b-col>
            <b-col cols="2" class="text-center">
              <h6>Warranty</h6>
            </b-col>
            <b-col cols="5">
              <h6>Warranty Details</h6>
            </b-col>
            <b-col cols="5"> </b-col>
          </b-row>
          <div
            class="issue-item"
            v-for="(issue, index) in model.issues"
            :key="issue.issue._id"
          >
            <b-row>
              <b-col cols="2" class="align-self-center">
                <div class="issue-details">
                  <div class="issue-icon">
                    <img :src="issue.issue.icon" alt="" />
                  </div>
                  <div class="issue-name">
                    <h5>{{ issue.issue.name }}</h5>
                  </div>
                </div>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Price" label-for="issue-price">
                  <b-form-input
                    v-model="model.issues[index].price"
                    id="issue-price"
                    type="number"
                    required
                    placeholder="Price"
                  />
                </b-form-group>
                <b-form-group
                  label="Strike Through Price"
                  label-for="issue-price"
                >
                  <b-form-input
                    v-model="model.issues[index].strikeThroughPrice"
                    id="issue-strike-price"
                    type="number"
                    placeholder="Strike Through Price"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                class="d-flex align-self-center text-center justify-content-center"
              >
                <div>
                  <b-form-checkbox
                    :id="`is-warranty-available-${issue.issue.name}`"
                    :name="`is-warranty-available-${issue.issue.name}`"
                    :value="true"
                    v-model="model.issues[index].warranty.isAvailable"
                  >
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col cols="5" class="align-self-center">
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Warranty Price"
                      label-for="issue-price"
                    >
                      <b-form-input
                        :disabled="!model.issues[index].warranty.isAvailable"
                        v-model="model.issues[index].warranty.price"
                        id="hi-number"
                        type="number"
                        required
                        placeholder="Price"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Warranty Period"
                      label-for="issue-price"
                    >
                      <b-form-input
                        :disabled="!model.issues[index].warranty.isAvailable"
                        v-model="model.issues[index].warranty.period"
                        id="hi-number"
                        type="number"
                        required
                        placeholder="Period"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Period Value" label-for="issue-price">
                      <b-form-select
                        v-model="model.issues[index].warranty.periodValue"
                        :options="periodValueOptions"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1" class="align-self-center">
                <div>
                  <b-form-checkbox
                    :id="`is-private-issue-${issue.issue.name}`"
                    :name="`is-private-issue-${issue.issue.name}`"
                    :value="true"
                    v-model="model.issues[index].isPrivate"
                  >
                    <feather-icon
                      :icon="
                        model.issues[index].isPrivate ? 'EyeOffIcon' : 'EyeIcon'
                      "
                    ></feather-icon>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-backdrop
      v-model="showIssueSelectionModal"
      id="issue-selection-modal"
      content-class="shadow"
      title="Add Issues"
      no-fade
      ok-only
      ok-title="Add Issues"
      @ok="addIssues"
    >
      <b-card-text>
        <b-form-group label="Issues">
          <v-select
            placeholder="Select Issue(s)"
            multiple
            v-model="issues"
            :options="issuesList"
            label="name"
          >
            <template #option="{ name, icon }">
              <img :src="icon" />
              <span> {{ name }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import MediaUploader from "../../components/MediaUploader.vue";
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormSelect,
  BFormGroup,
  BSpinner,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import moment from "moment-timezone";
import vSelect from "vue-select";

export default {
  data() {
    return {
      model: {
        media: {
          image: "",
          alt: "",
        },
      },
      brand: null,
      breadcrumbs: [
        {
          text: "Dashboard",
          to: "/dashboard",
        },
        {
          text: "Brands",
          to: "/brand",
        },
      ],
      periodValueOptions: [
        { value: null, text: "Select warranty period value" },
        { value: "Days", text: "Day(s)" },
        { value: "Months", text: "Month(s)" },
        { value: "Years", text: "Year(s)" },
      ],
      updatingModel: false,
      showIssueSelectionModal: false,
      issuesList: [],
      issues: [],
      issueListResponse: [],
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BSpinner,
    MediaUploader,
    BCardTitle,
    vSelect,
    BCardText,
  },
  computed: {},
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getModel() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/model/get-model/${this.$route.params.model}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.breadcrumbs.push({
            text: response.data.brand.name,
            to: `/brand/${response.data.brand.slug}`,
          });
          this.breadcrumbs.push({
            text: response.data.name,
            active: true,
          });
          this.model = response.data;
          this.getOptions();
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    getFormattedTime(time) {
      return moment(time)
        .tz("Asia/Kolkata")
        .format("MMM Do YYYY, h:mm a");
    },
    updateDeviceDetails() {
      this.updatingModel = true;
      let model = JSON.parse(JSON.stringify(this.model));
      for (let i = 0; i < model.issues.length; i++) {
        const issue = model.issues[i];
        let issueID = issue.issue._id;
        model.issues[i].issue = issueID;
      }

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/model/update`,
          {
            model: model._id,
            updates: model,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.updatingModel = false;
          this.makeToast("success", "Success", `Model updated successfully!`);
        })
        .catch((error) => {
          this.updatingModel = false;
          console.log(error.response.data);
          this.makeToast(
            "danger",
            "Error",
            `Error occured while creating brand!`
          );
        });
    },
    handleModelImageChange(imageData) {
      if (imageData.status) {
        this.model.media.image = imageData.media.src;
      }
    },
    addMoreIssues() {
      this.showIssueSelectionModal = true;
    },
    updateAvailableIssues(issues) {
      let selectedIssues = this.model.issues;
      let availableIssues = [];
      for (let i = 0; i < issues.length; i++) {
        const issue = issues[i];
        let index = selectedIssues.findIndex((selectedIssue) => {
          return selectedIssue.issue._id == issue._id;
        });
        if (index < 0) {
          availableIssues.push({
            issue: issue._id,
            price: 0,
            strikeThroughPrice: 0,
            icon: issue.icon,
            name: issue.name,
            warranty: {
              isAvailable: false,
              price: 0,
              period: 0,
              periodValue: "m",
            },
          });
        }
      }

      this.issuesList = availableIssues;
    },
    getOptions() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/model/get-options`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          const { issues } = response.data;
          this.issueListResponse = issues;
          this.updateAvailableIssues(issues);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    addIssues() {
      for (let i = 0; i < this.issues.length; i++) {
        const issue = this.issues[i];
        this.model.issues.push({
          warranty: {
            isAvailable: false,
            price: 0,
            period: 0,
            periodValue: "m",
          },
          issue: {
            _id: issue.issue,
            name: issue.name,
            icon: issue.icon,
          },
          price: 0,
          strikeThroughPrice: 0,
        });
      }
      this.issues = [];
      this.updateAvailableIssues(this.issueListResponse);
    },
  },
  mounted() {
    this.getModel();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.device-image {
  max-width: 80px;
  margin: 0 auto;
}
.issue-details {
  display: flex;
  align-items: center;
}
.issue-details img {
  max-width: 70%;
}
.issue-details h6,
.issue-details h5 {
  margin: 0 0 0 0px;
}
.issue-item {
  margin: 12px 0;
  border-bottom: 1px dashed #000;
}
.strike-price-input {
  margin-top: 2px;
}
.issue-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.issue-card-title h4 {
  margin: 0;
}
</style>
